import i18n from "./i18n";

const { t } = i18n.global;

export const required = (value) => {
  if (!value || !value.length) {
    return t("errors.fieldRequired");
  }
  return true;
};

export const decathlonEmail = (value) => {
  if (
    !/^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@decathlon.com$/.test(value)
  ) {
    return t("errors.emailValidation");
  }
  return true;
};

export const phoneNumber = (value) => {
  if (!/^[+]{0,1}?\d+$/.test(value)) {
    return t("errors.phoneValidation");
  }
  return true;
};

export const siteEmail = (value) => {
  if (
    !/^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*$/.test(
      value
    )
  ) {
    return t("errors.email");
  }
  return true;
};

export const email = (value) => {
  if (
    !/^(?=.{1,64}@)[A-Za-z0-9+_-]+(\.[A-Za-z0-9+_-]+)*@([\w-]+\.)+[\w-]{2,4}$/.test(
      value
    )
  ) {
    return t("errors.email");
  }
  return true;
};

export const fax = (value) => {
  if (!/^\+?\d{9,20}$/.test(value)) {
    return t("errors.fax");
  }
  return true;
};

export const numberText = (value) => {
  // Checks if the value contains only numbers from 0 to 9
  if (/^[0-9]+$/.test(value)) {
    return true;
  } else {
    return t("errors.number");
  }
};
