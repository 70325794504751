// strings to use around the app
const messages = {
  "es-ES": {
    app: {
      name: "Decacare",
      error: "404",
      pageNotFound: "Página no encontrada",
      contactAdministrator: "Por favor, contácte con algún administrador",
      unexpectedError: "Error inesperado",
      sessionExpired: "La sesión ha expirado",
      returnLogin: "Ingrese nuevamente",
    },
    breadCrumbs: {
      start: "Home",
      admin: "Admin",
      accident: "Accidente",
      incident: "Incidente",
      site: "Tienda",
      profiles: "Perfiles",
      centerAndZones: "Centros y zonas",
      insuranceCenter: "Centros de seguro",
      addCenterAndZones: "Añadir zonas y centros",
      myAccidents: "Mis accidentes",
      myIncidents: "Mis incidentes",
      accidentsIncidentsList: "Listado de accidentes e incidentes",
      centerAccidents: "Accidentes en el centro",
      centerIncidents: "Incidentes en el centro",
      countryAccidents: "Accidentes en el país",
      countryIncidents: "Incidentes en el país",
      jobs: "Perfiles de trabajo",
      myProfile: "Mi perfil",
      accidentManagement: "Gestión de accidentes",
    },
    menu: {
      title: "Decacare",
      profile: "Perfil",
      admin: "Admin",
      logout: "Cerrar sesión",
      myProfile: "Mis datos",
      dataControl: "Control de datos",
      accidents: "Accidentes",
      incidents: "Incidentes",
      myPlans: "Mis planes",
      flyers: "Volantes",
      myAccidents: "Mis accidentes",
      myIncidents: "Mis incidentes",
      myActionPlans: "Mis planes de acción",
      myAssinstanceFlyers: "Mis volantes de asistencia",
      centerAccidents: "Accidentes en el centro",
      centerIncidents: "Incidentes en el centro",
      countryAccidents: "Accidentes del país",
      countryIncidents: "Incidentes del país",
      allThePlans: "Todos los planes de acción de mi centro",
      flyersCenter: "Volantes centro",
      data: "Datos",
      reportingData: "Extracción y reporting de datos",
      logoutError: "Error al deslogarse",
      logoutSuccessful: "Se ha deslogueado correctamente",
      loginAgain: "Ingrese nuevamente",
    },
    general: {
      greeting: "Hola {userName}!",
      canCreate: "Como {role}, puedes crear un accidente o un incidente",
      createEvent: "Crear Accidente/Incidente",
      asAdmin:
        "Como administrador, puedes ver accidentes o incidentes en cada tienda.",
      accidentDescription:
        "El colaborador se encuentra herido o inconsciente. En función de la gravedad se llamará al 112.",
      incidentDescription:
        "No ha llegado a producirse el accidente. Ayuda a prevenirlo.",
      missingInfoFields:
        "Por favor completa la información faltante en los campos",
    },
    roles: {
      ROL_COLABORATOR: "Rol Colaborador",
      ROL_MANAGER_SECTION: "Rol Emergencia",
      ROL_MANAGER_SECURITY: "Rol Seguridad",
      ROL_MANAGER_STORE: "Rol Tienda",
      ROL_MANAGER_COACH: "Rol Coach",
      ROL_ADMIN_HR: "Rol Admin",
      ROL_ADMIN_PRL: "Rol Técnico",
    },
    actions: {
      title: "Se requiere tu acción",
      subtitle: "Hay noticias que necesitan tu atención",
      createDescription: "Crear descripción de accidente/incidente",
      createDescriptionMobile: "Crear descripción",
      generateActionPlan: "Genere plan de acción",
      seeActionPlan: "Ver tu plan de acción",
      connectToSap: "Conectar con SAP",
      twoDaysToGenerate: "",
      twoDaysToCreate: "",
      oneDayLeft: "",
      accidentDraftTitle:
        "Ya existe un accidente en etapa DRAFT para el usuario seleccionado",
      accidentDraftDescription:
        "¿Desea continuar con el mismo accidente o comenzar uno nuevo?",
      accidentChangePlaceTitle:
        "El accidente tiene información contradictoria para el lugar del accidente seleccionado",
      accidentChangePlaceDescription:
        "Existe información previa correspondiente a otro lugar de accidente, ¿desea eliminarla y confirmar el cambio? ",
      incidentDraftTitle:
        "Ya existe un incidente en etapa DRAFT para el usuario seleccionado",
      incidentDraftDescription:
        "¿Desea continuar con el mismo incidente o comenzar uno nuevo?",
      incidentChangePlaceTitle:
        "El incidente tiene información contradictoria para el lugar del incidente seleccionado",
      incidentChangePlaceDescription:
        "Existe información previa correspondiente a otro lugar de incidente, ¿desea eliminarla y confirmar el cambio? ",
      incidentActionPlanTitle:
        "Existe información contradictoria para el incidente",
      incidentActionPlanDescription:
        "Existe información previa correspondiente a un accidente que impide agregar un plan de acción, ¿desea eliminarla y confirmar el cambio?",
    },
    status: {
      accidentCreated: "Accidente creado",
      incidentCreated: "Incidente creado",
      actionPlanReady: "Plan de acción listo",
      resolved: "Resuelto",
      availableTime: "Tienes 24 horas para rellenar",
      available: "Disponible",
    },
    events: {
      myEvents: "Listado de tus accidentes y incidentes",
      centerEvents: "Listado de accidentes e incidentes en tu centro",
      countryEvents: "Listado de accidentes e incidentes en el país",
      centerAccidents: "Accidentes de mi centro",
      centerIncidents: "Incidentes de mi centro",
      countryAccidents: "Accidentes en el país",
      countryIncidents: "Incidentes en el país",
      accident: "Accidente",
      incident: "Incidente",
    },
    admin: {
      optionYes: "Si",
      optionNot: "No",
      title: "Espacio de administrador",
      subtitle:
        "Aqui tienes la opción de visualizar a nivel nacional todos los perfiles, centros, accidentes e incidentes.",
      caption: "Además, podrás modificar y comunicar los accidentes.",
      profiles: "Perfiles de empleados",
      jobProfiles: "Perfiles de trabajo",
      modifyProfile: "Modificar el rol de cada perfil.",
      modifyJobRole: "Modificar el rol de cada trabajo.",
      centerAndZones: "Centros y Zonas",
      insuranceCenter: "Centros de seguro",
      createAndModifyZones:
        "Crear y modificar los datos de los centros y las zonas del país.",
      createAndModifyInsurance:
        "Crear, modificar y asignar los centros de seguro.",
      connection: "Conexión",
      SAPconnection: "Realizar la comunicación con SAP",
      accidents: "Accidentes",
      searchAndModifyAccidents: "Crear, buscar y modificar accidentes.",
      incidents: "Incidentes",
      searchAndModifyIncidents: "Crear, buscar y modificar incidentes.",
      SAPcommunication: "Comunicación con SAP",
      accidentManagement: "Gestión de accidentes",
      successfullySent: "Se ha enviado correctamente",
      deliveryFailed:
        "Envio fallido. Intente nuevamente y si el problema persiste, comuníquese con soporte",
      countryAccidents: "Accidentes en el país",
    },
    buttons: {
      validateAccident: "Validación",
      accidentManagement: "Gestionar",
      enter: "Entrar",
      discover: "Descubrir",
      moreDetails: "Más detalles",
      seeDetails: "Ver detalles",
      more: "Ver más",
      home: "Inicio",
      createAccident: "Crear Accidente",
      createIncident: "Crear Incidente",
      selectSeveralStores: "Seleccione varias tiendas",
      selectOneStore: "Seleccione una tienda",
      modifyRoles: "Modificar los roles",
      editEmployee: "Modificar empleados",
      createNew: "Crear nuevo",
      modify: "Modificar",
      SAPconnection: "Comunicar con SAP",
      signDocument: "Firmar el documento",
      image: "Imagen",
      delete: "Borrar",
      startFilling: "Comience a rellenar",
      saveAndPreview: "Guardar e ir a la vista previa",
      back: "Atrás",
      save: "Guardar",
      next: "Siguiente",
      confirm: "Confirmo",
      preview: "Vista previa",
      send: "Enviar",
      viewRecord: "Ver el registro",
      returnMainPage: "Volver a la página principal",
      apply: "Aplicar",
      accidentDate: "Fecha de accidente",
      Date: "Fecha de accidente",
      modifyRole: "Modificar el rol",
      assignCenters: "Asignar centros",
      cancel: "Cancelar",
      changeZone: "Cambiar zona",
      changeDetails: "Modificar detalles",
      addRelapse: "Añadir parte",
      createCenter: "Crear centro",
      createZone: "Crear zona",
      createActionPlan: "Crear plan de acción",
      addResources: "Añadir recursos",
      incidentDate: "Fecha de incidente",
      goToAccident: "Ir al accidente",
      goToIncident: "Ir al incidente",
      createDescription: "Crear descripción",
      continue: "Continuar",
      completeProfile: "Completar perfil",
      addUser: "Agregar usuario",
      createJob: "Crear perfil de trabajo",
      search: "Buscar",
      sendToSAP: "Enviar a SAP",
      markAsManaged: "Marcar como gestionado",
      moreActions: "Más acciones",
      referredToSS: "Derivado a la SS (SPS)",
      markAsClosed: "Marcar como finalizado",
      witnesses: "Testigo",
      downloadTxt: "Descargar txt",
      downloadCsv: "Descargar csv",
      downloadFlyer: "Descargar volante",
      register: "Registrar",
      manage: "Gestionar",
      syncSites: "Sincronizar tiendas",
      editUser: "Editar usuario",
      accept: "Aceptar",
      asepeyoData: "Datos Asepeyo",
      syncData: "Sincronizar información",
      selectAll: "Seleccionar todos",
      deselectAll: "Deseleccionar todos",
      deleteAccident: "Eliminar",
      edit: "Editar",
      update: "Actualizar",
      updateMedicalReport: "Actualizar parte",
      addMedicalReport: "Añadir parte",
      add: "Añadir",
      deleteMultiCenter: "Eliminar Centros actuales",
    },
    filters: {
      filter: "Filtrar",
      select: "Seleccionar",
      order: "Orden",
      oldest: "Más antigua",
      recent: "Reciente",
      searchBy: "Buscar código, centro o municipio",
      selectCenter: "Seleccione centro",
      sap: "SAP",
      uid: "UID",
      nameProfile: "Perfil",
      nif: "NIF",
      nif_nie: "NIF/NIE",
      nie: "NIE",
      email: "Email",
      passport: "Pasaporte",
      dni: "DNI",
      completeName: "Nombre y apellido",
      centers: "Centros",
      zones: "Zonas",
      searchByReference: "Busca por numero de referencia",
      searchByNameOrCode: "Buscar por código o nombre",
      searchByAllFields:
        "Buscar por ID, nombre de colaboradores, UID, SAP o NIF",
      searchByCenterOrCollaborator: "Busca por centro o por colaborador",
      unknown: "Desconocido",
      partial: "Parcial",
      temporal: "Temporal",
      undefined: "Indefinido",
      permanent: "Permanente",
      external: "Externo",
      lessOnemonth: "Menos de 1 mes",
      oneToThreeMonths: "Entre 1 y 3 meses",
      threeToSixMonths: "Entre 3 y 6 meses",
      sixToTwelves: "Entre 6 y 12 meses",
      greaterThanTwelve: "Más de 12 meses",
      name: "Nombre",
      surname: "Apellido",
      firstSurname: "Primer apellido",
      lastSurname: "Segundo apellido",
      zone: "Zona",
      filterByZone: "Filtrar por zona/s",
    },
    emptyState: {
      noResultsFound: "No se encontraron resultados",
    },
    accidentForm: {
      witness: "Testigo:",
      signManager: "Firma de jefe de emergencia*",
      witnessDescription: "Descripción del testigo",
      collaboratorSignature: "Firma del colaborador",
      managerSignature:
        "La firma del documento será obligatoria por parte del jefe de emergencia. Firme su nombre en el espacio abajo",
      rememberAlert:
        "Recuerde que las autoridades interesadas serán notificadas",
      collaboratorSign: "Firma del colaborador:",
      collaboratorValidation:
        "Si el colaborador no puede firmar, seleccione la verificación. Si el Colaborador puede firmar, haga clic en el botón ‘Siguiente’.",
      collaboratorCantValidate:
        "Colaborador no puede validar o esta incapacitado para validar.",
      whoIsTheCollaborator: "¿Quién es el colaborador herido?",
      searchCollaborator:
        "Buscar y seleccionar colaborador implicado en el accidente. Si su nombre o apellido no aparece, busque usando su NIF/NIE, SAP o EMAIL.",
      searchBy: "Busca por nombre, apellidos, SAP, NIF o email",
      preview: "Vista previa",
      checkAndConfirm: "Revisa y confirma los datos antes de enviar",
      collaboratorInvolved: "Colaborador implicado",
      whatHappened: "¿Qué ha ocurrido?",
      collaboratorDescription: "Descripción de colaborador accidentado:",
      collaboratorDescriptionAlert:
        "Descripción de colaborador accidentado: A los colaboradores se les permitirán agregar sus descripciones después de enviar este formulario.",
      managerDescription: "Descripción de jefe de emergencia",
      accidentLevel: "Nivel de accidente",
      dateAndHour: "Fecha y hora de accidente:",
      workPlace: "Accidente en el centro de trabajo habitual",
      accidentSite: "Lugar del accidente",
      workingDayMissionDisplacement:
        "Desplazamiento en su jornada laboral (desplazamiento en misión)",
      inItinereAccident:
        "In itinere: en el desplazamiento, antes o finalizada su jornada laboral",
      accidentInOtherCompany:
        "En otro centro o lugar de trabajo ajeno a Decathlon",
      workingHoursAccident: "Cuantas horas llevó trabajando",
      takingPlaceActivity: "¿Qué actividad se estaba realizando?",
      workTypeActivity: "Tipo de trabajo",
      basicCause: "Causa Básica",
      causeAndEffectAccident: "Causa y hecho, ¿cómo ha ocurrido el accidente?",
      registeredAccident: "¡Ya has registrado el accidente!",
      selectCenter: "Seleccione el centro de seguro vinculado",
      healthInsuranceCenterLinked: "Centro de seguro vinculado",
      asepeyoCenter: "Centro de Asepeyo",
      selectAsepeyoCenter: "Elige el centro de Asepeyo",
      collaboratorAllowToAddDescription:
        "A los colaboradores se les permitirán agregar sus descripciones después de enviar este formulario.",
      editableNote: "Nota: Será editable solo durante 48 horas.",
      emergencyChiefDescription: "Descripción de jefe de emergencia*",
      howSerious: "¿Cómo de grave consideras el accidente?",
      accidentDateHour: "Fecha y hora del accidente",
      date: "Fecha",
      hour: "Hora",
      intervals: "Intervalos",
      calendar: "Calendario",
      today: "Hoy",
      yesterday: "Ayer",
      storeZone: "Zona de la tienda dónde ha ocurrido el accidente",
      wareHouseZone: "Zona del almacén",
      centralServices: "Zona de servicios centrales",
      kuikma: "Zona de Kuikma",
      conceptionCenter: "Centro de concepción",
      missionDisplacement: "Desplazamiento en su jornada laboral",
      decathlonCenter: "Centro Decathlon",
      store: "Tienda",
      inItinere:
        "In Itinere: en el desplazamiento, antes de iniciar o al finalizar su jornada laboral",
      inItinereAlert:
        "Accidente que sufre el trabajador en el trayecto habitual para llegar al trabajo o volver a su casa.",
      address: "Dirección y descripción detallada del accidente",
      inItinerePostalCode: "Código postal del lugar del accidente",
      inItinerePlace: "Lugar del accidente (in itinere)",
      companyName: "Nombre de la empresa",
      companyCIF: "CIF de la empresa",
      workingHours: "¿Cuántas horas llevaba trabajando el colaborador?",
      others: "Otros",
      causeAndFact: "¿Causa y hecho, cómo ha ocurrido el accidente?",
      alertText: "Enviar notificación a las autoridades interesadas",
      step1: "Colaborador implicado",
      step2: "Rellenar el accidente",
      step3: "Vista previa",
      step4: "Firma",
      step5: "¡Hecho!",
      dateOfTheAccident: "Fecha del accidente:",
      hourOfTheAccident: "Hora del accidente:",
      nameOfTheCompany: "Nombre de la empresa:",
      cifOfTheCompany: "CIF de la empresa:",
      asepeyoNotification: "Envio al centro de seguro",
      emailSentSuccesfully: "Email enviado correctamente",
      emailSentUnuccesfully:
        "Hubo un problema con el envio del email, si el problema persiste, contactar con algún administrador",
    },
    incidentForm: {
      collaboratorsInvolved: "Colaborador implicado",
      collaboratorInvolved: "Colaborador implicado",
      incidentAlert:
        "No ha llegado a producirse el accidente. Ayuda a prevenirlo. La persona no necesita asistencia por parte de la mutua (Asepeyo)",
      searchCollaborator:
        "Buscar y seleccionar colaborador implicado en el accidente. Si su nombre o apellido no aparece, busque usando su NIF/NIE, SAP o EMAIL.",
      step1: "Colaborador implicado",
      step2: "Rellenar el incidente",
      step3: "Plan de acción",
      step4: "Vista previa",
      step5: "¡Hecho!",
      registeredIncident: "¡Ya has registrado el incidente!",
      searchBy: "Busca por nombre, apellidos, SAP, NIF o email",
      whatHappened: "¿Qué ha ocurrido?",
      collaboratorAllowToAddDescription:
        "A los colaboradores se les permitirán agregar sus descripciones después de enviar este formulario.",
      editableNote: "Nota: Será editable solo durante 48 horas.",
      emergencyChiefDescription: "Descripción del incidente",
      incidentDateHour: "Fecha y hora del incidente",
      date: "Fecha",
      hour: "Hora",
      workPlace: "Lugar que se ha producido el incidente",
      storeZone: "Lugar en la tienda que se ha producido el incidente",
      wareHouseZone: "Lugar en el almacén que se ha producido el incidente",
      centralServices:
        "Lugar en servicios centrales que se ha producido el incidente",
      kuikma: "Lugar en Kuikma que se ha producido el incidente",
      conceptionZone:
        "Lugar en el centro de concepción que se ha producido el incidente",
      actionPlan: "Plan de acción",
      actionPlanSuggest:
        "¿Puede mejorarse algún aspecto del entorno para que éste no represente un riesgo?",
      preview: "Vista previa",
      checkAndConfirm: "Revisa y confirma los datos antes de enviar",
      incidentDescription: "Descripción del incidente producido",
      incidentSite: "Lugar del incidente",
      actionPlanProposed: "Plan de acción propuesto",
      sendActionPlan: "Envía el plan de acción al colaborador",
      sendActionPlanDescription:
        "(Tambien le llegará un mail al director de la tienda con lo sucedido y el plan de acción desarrollado)",
    },
    formOptions: {
      unknownTitle: "0. Desconocido",
      unknownContent: "El tipo de accidente es desconocido.",
      mildTitle: "1. Leve (sin/con baja)",
      mildContent:
        "La persona está consciente y puede moverse por si misma. Por ejemplo cortes, caidas, y golpes sin excesiva gravedad.",
      seriousTitle: "2. Grave",
      seriousContent:
        "La persona no está consciente ni puede moverse por sí misma. El colaborador tiene heridas importantes y necesita asistencia sanitaria en el lugar del accidente y traslado a un centro hospitalirio Por ejemplo rotura de huesos, tendones.",
      verySeriousTitle: "3. Muy grave",
      verySeriousContent:
        "La persona no está consciente ni puede moverse por sí misma. Por ejemplo Amputación o pérdida de sentido (inconsciencia).",
      deadlyTitle: "4. Accidentes mortales",
      deadlyContent:
        "Los daños personales ocasionan el fallecimiento del trabajador o trabajadora.",
      accidentSite1: "Accidente en el centro de trabajo habitual",
      accidentSite2: "Accidente ocurrido en otro centro decathlon",
      accidentSite3:
        "In Itinere: en el desplazamiento, antes de iniciar o al finalizar su jornada laboral",
      accidentSite4: "En otra empresa",
      zone1: "Tienda",
      zone2: "Centro logístico / Service Center",
      zone3: "Servicios centrales",
      zone4: "Centro de concepción",
      journey1: "Al ir al trabajo",
      journey2: "Al volver del trabajo",
      storeZone1: "Muelle/Reserva",
      storeZone2: "Salas comunes",
      storeZone3: "Parking",
      storeZone4: "Realizando actividad deportiva (Interiores o exteriores)",
      storeZone5: "CGBT, sala de grupo electrógeno o cuarto de bombas",
      storeZone6: "Zona comercial",
      wareHouseZone1: "Muelles de carga y descarga",
      wareHouseZone2: "Pasillo perimetral",
      wareHouseZone3: "Parking",
      wareHouseZone4: "Pasillo heteroclitos",
      wareHouseZone5: "Zona de tránsito",
      wareHouseZone6: "Oficinas",
      wareHouseZone7: "Cuartos Técnicos",
      wareHouseZone8: "Salas Comunes",
      wareHouseZone9: "Actividad Deportiva",
      wareHouseZone10: "Taller regional",
      wareHouseZone11: "Zona consolidación de mercancía",
      wareHouseZone12: "Zona de picking",
      centralServicesZone1: "Estacionamiento",
      centralServicesZone2: "Escaleras",
      centralServicesZone3: "Ascensor",
      centralServicesZone4: "Sala de reuniones",
      centralServicesZone5: "Planta 0",
      centralServicesZone6: "Planta 1",
      centralServicesZone7: "Planta 2",
      centralServicesZone8: "Planta 3",
      centralServicesZone9: "Zonas comunes",
      kuikmaZone1: "Oficina",
      kuikmaZone2: "Almacén",
      kuikmaZone3: "Test",
      conceptionCenterZone1: "Oficina",
      conceptionCenterZone2: "Almacén",
      conceptionCenterZone3: "Test",
      workedHours1: "1 Hora",
      workedHours2: "2 Horas",
      workedHours3: "3 Horas",
      workedHours4: "4 Horas",
      workedHours5: "5 Horas",
      workedHours6: "6 Horas",
      workedHours7: "7 Horas",
      workedHours8: "8 Horas",
      workedHours9: "9 Horas",
      workedHours10: "10 Horas",
      workedHours11: "11 Horas",
      workedHours12: "12 Horas",
      workedHours13: "> 12 Horas",
      activity1: "Usando carretillas",
      activity2: "Transpalet manual",
      activity3: "Utilizando herramientas de taller",
      activity4: "Realizando una mudanza",
      activity5: "Facing",
      activity6: "Picking",
      activity7: "Andando, subir o bajar escaleras",
      activity8: "Otros",
      cause1: "Fuego o incendio",
      cause2: "Contacto eléctrico",
      cause3: "Material que colisiona con el trabajador",
      cause4: "Pérdida de control de la maquinaria",
      cause5: "Agresión por atraco",
      cause6: "Quedar atrapado",
      cause7: "Caída del colaborador",
      cause8: "Cuartos técnicos",
      cause9: "Corte",
      cause10: "Otras",
      center1: "Enviar a tu centro de Asepeyo",
      center2:
        "Enviar a otro centro de Asepeyo diferente al que corresponde a mi centro",
      center3: "Accidentado no acude al centro médico",
      workType1: "Picking reapro",
      workType2: "Descarga o carga",
      workType3: "Inventario / gestión de stock",
      workType4: "Tienda",
      workType5: "Actividades deportivas",
      workType6: "Otros, in itinere, en otra empresa",
      returned: "Fin de la baja",
      injuried: "Accidente con baja",
      confirmed: "Parte de confirmación",
      doesNotCauseSickLeave: "Accidente sin baja",
      ableToWorkTomorrow: "Reposo",
      workAccident: "Accidente de trabajo",
      professionalSick: "Enfermedad profesional",
      slight: "Leve",
      serious: "Grave",
      verySerious: "Muy grave",
      mortal: "Mortal",
      basicCause1: "Sobreesfuerzos por Manipulación manual de cargas",
      basicCause2: "Actividad física/deportiva",
      basicCause3: "Caída al mismo o distinto nivel",
      basicCause4: "Choque o Golpe",
      basicCause5: "Corte",
      basicCause6: "En otra empresa",
      basicCause7: "In itinere",
      basicCause8: "Recaída",
      basicCause9: "Contacto con sustancias nocivas o tóxicas/intoxicación",
      basicCause10: "In Misión",
      basicCause11: "Atropello",
      basicCause12: "Atrapamiento",
      basicCause13: "Quemaduras",
    },
    timeLine: {
      flyerGeneration: "Generación de Volante",
      asepeyoClasification: "Clasificación Asepeyo",
      actionPlan: "Plan de acción",
      backToWork: "Regresó al trabajo",
      sendToSAP: "Enviar a SAP",
      completed: "Finalizado",
      step1: "Etapa 1",
      step2: "Etapa 2",
      step3: "Etapa 3",
      step4: "Etapa 4",
      step5: "Etapa 5",
      managed: "Gestionado",
      managedByHR: "Gestionado por RRHH",
      returned: "Regresó",
    },
    rolesOption: {
      role1: "01 - Rol Colaborador",
      role2: "02 - Rol Emergencia",
      role3: "03 - Rol Seguridad",
      role4: "04 - Rol Tienda",
      role5: "05 - Rol Coach",
      role6: "06 - Rol Admin",
      role7: "07 - Rol Técnico",
      undefined: "Rol sin definir",
    },
    adminCenter: {
      id: "Número de identificación:",
      name: "Nombre:",
      zone: "Zona:",
      stores: "Tiendas:",
      description: "Descripción",
      centerNumber: "Número del centro:",
      centerName: "Nombre del centro:",
      phoneNumber: "Teléfono:",
      phone: "Teléfono:",
      accountNumber: "Código Cuenta Cotización:",
      address: "Dirección:",
      region: "Región:",
      startDate: "Fecha de inicio:",
      endDate: "Fecha de fin:",
      decathlonRegion: "Región Decathlon:",
      doctorName: "Nombre del médico:",
      asepeyoAddress: "Dirección Asepeyo:",
      zipAsepeyo: "ZIP Asepeyo:",
      asepeyoEmail: "Email Asepeyo:",
      asepeyoPhone: "Teléfono Asepeyo:",
      asepeyoFax: "Fax Asepeyo:",
      changeCenterZone: "Cambiar la zona del centro",
      currentZone: "Zona actual:",
      newZone: "Zona nueva:",
      selectNewZone: "Seleccione una nueva zona",
      changeCenterName: "Modificar detalles del centro",
      addZoneAndCenter: "Añadir Zona/Centro",
      zoneName: "Nombre de la zona:",
      changeJobRoles: "Modificar perfiles de trabajo",
      createJob: "Crear un perfil de trabajo",
      jobId: "Identificador del perfil de trabajo",
      jobName: "Nombre del perfil de trabajo",
      jobRole: "Rol del perfil de trabajo",
      missingInformation: "Información faltante",
      email: "Email:",
      complement: "Información adiccional de la dirección:",
      zipCode: "Código postal:",
      city: "Ciudad:",
      country: "Pais:",
      linkedSite: "Tienda vinculada:",
      linkedInsuranceCenter: "Centro de seguro vinculado:",
      siteAddress: "Dirección de la tienda:",
      siteComplement: "Complemento de la tienda:",
      siteZipCode: "Código postal de la tienda:",
      siteCity: "Ciudad de la tienda:",
      siteCountry: "País de la tienda:",
      stateProcess: "Estado del proceso:",
      autonomousCommunity: "Comunidad autónoma:",
      faxNumber: "Fax:",
      insuranceCenters: "Centros de seguro:",
      zoneUndefined: "Región sin definir",
      staffTemplate: "Plantilla media:",
    },
    adminProfile: {
      id: "Número de identificación",
      collaboratorName: "Nombre del colaborador/a:",
      name: "Nombre:",
      collaboratorFirstSurname: "Apellido 1 del colaborador/a:",
      collaboratorSecondSurname: "Apellido 2 del colaborador/a:",
      profile: "Perfil:",
      email: "Correo electrónico:",
      phoneNumber: "Número de teléfono:",
      role: "Rol:",
      workCenter: "Centro de trabajo:",
      job: "Oficio:",
      chief: "Manager:",
      sap: "SAP:",
      contractType: "Tipo de contrato:",
      accidentsIncidentsInvolved:
        "Accidentes e incidentes en los que ha estado:",
      changeRole: "Cambiar rol",
      assignCenters: "Asignar centros",
      assignedCenters: "Centros asignados",
      currentRole: "Rol actual:",
      newRole: "Nuevo rol:",
      currentSite: "Centros actuales:",
      newSite: "Nuevos centros:",
      selectNewRole: "Seleccione nuevo rol",
      documentNumber: "Número de documento:",
      documentType: "Tipo de documento:",
      missingInformation: "Información faltante",
      editProfile: "Modificar detalles del usuario",
    },
    centersCard: {
      title: "Centros",
      description:
        "Ver y modificar los centros. Puedes buscar por nombre o código de centro o utilizar el filtro de las zonas.",
      searchByZones: "Filtrar por zona/s",
      searchByCodeOrName: "Busca por código o nombre de centro",
      addCenter: "Añadir Centro",
      addZone: "Añadir Zona",
      insuranceCenter: "Centros de seguro",
      insuranceDescription:
        "Ver y modificar los centros. Puedes buscar por nombre de centro o utilizar el filtro de tiendas.",
      centerList: "Listado de centros",
    },
    insuranceCenter: {
      id: "Número de identificación",
      name: "Nombre",
      phone: "Teléfono",
      email: "Email",
      address: "Dirección",
      complement: "Complemento",
      zipCode: "Código postal",
      city: "Ciudad",
      country: "Pais",
      fax: "Fax",
      sites: "Tienda",
    },
    profilesCard: {
      title: "Perfiles",
      description:
        "Ver y modificar los perfiles. Puedes buscar o usar los filtros.",
      searchByCodeOrName: "Busca por Nombre, UID, SAP o NIF",
      changeProfileInfo: "Modificar detalles del perfil",
    },
    jobsCard: {
      title: "Perfiles de trabajo",
      description: "Ver y modificar los perfiles de trabajo.",
      searchByName: "Buscar por nombre de trabajo",
    },
    userForm: {
      involvedPersonData: "Datos de la persona involucrada",
      reviewProfileInfo: "Completa los datos del colaborador",
      name: "Nombre de la persona involucrada:",
      id: "Número de centro:",
      firstSurname: "Apellido 1 la persona involucrada:",
      secondSurname: "Apellido 2 la persona involucrada:",
      birthDate: "Fecha de nacimiento:",
      profile: "Perfil:",
      email: "Correo electrónico:",
      phone: "Número de teléfono:",
      role: "Rol:",
      site: "Centro de trabajo",
      job: "Oficio:",
      manager: "Patrón",
      managerProfile: "Perfil del manager:",
      managerName: "Nombre del manager:",
      managerSurname: "Apellido del manager:",
      managerEmail: "Email del manager:",
      hrId: "SAP:",
      contractType: "Tipo de contrato:",
      documentNumber: "Número de documento:",
      documentType: "Tipo de documento:",
      accidentsIncidentsInvolved:
        "Accidentes e incidentes en los que ha estado:",
      startDate: "Fecha de antigüedad en la empresa:",
      jobSeniority: "Antigüedad en el puesto de trabajo:",
      universe: "Universo:",
      address: "Dirección:",
      complement: "Complemento:",
      city: "Ciudad:",
      zipCode: "Código postal:",
      country: "País:",
      nationalSecurityNumber: "Número de seguridad social:",
      siteName: "Nombre:",
      siteCode: "Id de tienda:",
      sitePhone: "Teléfono de la tienda:",
      siteAddress: "Calle:",
      siteComplement: "Complemento:",
      siteCity: "Ciudad:",
      siteZip: "Código postal:",
      siteCountry: "País:",
      costCenter: "Código cuenta cotización:",
      seniorityDate: "Antigüedad:",
    },
    axidentDetails: {
      accidentDetails: "Detalles del accidente",
      flyer: "Volante:",
      accidentType: "Tipo de accidente:",
      notQualified: "No está clasificado por Asepeyo todavía.",
      accidentLeave: "Accidente con baja",
      actionPlan: "Plan de acción:",
      actionPlanResources: "Recursos plan de acción:",
      actionPlanNotCreated: "No está creado todavía",
      pendingShipmentSAP: "Pendiente de envio a SAP",
      sapConection: "Conexión con SAP:",
      sapNotConected: "No está conectado todavía. Puedes conectar manualmente.",
      stage1: "Etapa 1:",
      stage2: "Etapa 2:",
      stage3: "Etapa 3:",
      stage4: "Etapa 4:",
      actionPlanGenerated: "Plan de acción generado",
      accidentSeverity: "Nivel de accidente:",
      accidentSeverity1: "1 - leve",
      accidentSeverity2: "2 - grave",
      accidentSeverity3: "3 - muy grave",
      accidentSeverity4: "4 - mortal",
      collaboratorsInvolved: "Personas involucrada:",
      accidentDate: "Fecha del accidente:",
      accidentHour: "Hora del accidente:",
      accidentPlace: "Lugar del accidente:",
      chiefDescription: "Descripción del accidente (jefe de emergencia):",
      collaboratorDescription: "Descripción del accidente (colaborador):",
      missionDisplacement: "Desplazamiento en su jornada laboral:",
      workingHours: "Cuantas horas llevo trabajando:",
      activity: "Actividad que se estaba realizando:",
      causeAndFacts: "Como ha ocurrido:",
      workTypes: "Tipo de trabajo:",
      workPlace: "Lugar de trabajo",
      collaboratorData: "Datos del accidentado",
      investigation: "Investigación",
      signatures: "Firmas",
      asepeyoData: "Datos Asepeyo",
      asepeyoDataFirstPart: "Datos Asepeyo - parte: 1",
      asepeyoDataSecondPart: "Datos Asepeyo - parte: 2",
      asepeyoDataPart: "Datos Asepeyo - parte",
      doYouWantToAddMedicalReport: "¿Deseas agregar datos del reporte médico?",
      validationInformation1: "Es necesario la validacion por parte del lider.",
      validationInformation2:
        "Recuerda que antes de validar debe estar rellenado el plan de acción.",
      accidentValidatedByLeader: "Accidente validado por líder",
    },
    actionPlan: {
      actionPlanAccident: "Plan de acción - Accidente",
      actionPlanRecomended:
        "¿Cuál es el plan de acción recomendado para el accidente?",
      actionPlanResources:
        "Recursos que consideras apropiados como un plan de acción",
    },
    incidentDetails: {
      incidentDetails: "Detalles del incidente",
      personalInvolved: "Personas involucradas:",
      createdBy: "Creado por:",
      incidentDate: "Fecha del incidente:",
      incidentHour: "Hora del incidente:",
      incidentPlace: "Lugar del incidente:",
      incidentZone: "Zona del incidente:",
      incidentChiefDescription:
        "Descripción del incidente (jefe de emergencia):",
      incidentCollaboratorDescription:
        "Descripción del incidente (colaborador):",
      actionPlan: "Plan de acción:",
      actionPlanResources: "Recursos del plan de acción:",
      incidentMedia: "Fotos/video del incidente:",
    },
    editAccident: {
      editAccident: "Modificar accidente",
      accidentSeverity: "Nivel de accidente:",
      collaboratorInvolved: "Colaborador implicado:",
      affectedUserId: "Id",
      affectedUserName: "Nombre:",
      affectedUserFirstSurname: "Primer apellido:",
      affectedUserLastSurname: "Segundo apellido:",
      affectedUserDocumentType: "Tipo de documento:",
      affectedUserDocumentNumber: "Número de documento:",
      affectedUserHrId: "SAP ID:",
      affectedUserNSN: "Número de seguridad social:",
      chiefDescription: "Descripción de jefe de emergencia:",
      collaboratorDescription: "Descripción de colaborador accidentado:",
      workingHours: "Cuantas horas llevo trabajando:",
      accidentDate: "Fecha:",
      accidentHour: "Hora:",
      accidentPlace: "Lugar del accidente:",
      accidentZone: "Zona del accidente:",
      missionDisplacement: "Desplazamiento en su jornada laboral:",
      activity: "Actividad que se estaba realizando:",
      causeAndFacts: "Como ha ocurrido:",
      workType: "Tipo de trabajo:",
      occurenceAddress: "Dirección del accidente:",
      site: "Tienda:",
      sitePhone: "Télefono de la tienda:",
      externalSiteName: "Sitio ajeno a Decathlon - nombre:",
      externalSiteCode: "Sitio ajeno a Decathlon - CIF:",
      emergencyChiefSignature: "Firma del jefe de emergencia:",
      userInvolvedSignature: "Firma de la persona afectada:",
      createRelapse: "Crear parte médico",
      updateMedicalReport: "Actualizar parte",
      basicCause: "Causa básica",
      witnessDescription: "Descripción de testigo:",
    },
    editIncident: {
      editIncident: "Modificar incidente",
      collaboratorsInvolved: "Colaborador/es implicado/s",
      affectedUserName: "Nombre:",
      affectedUserFirstSurname: "Primer apellido:",
      affectedUserLastSurname: "Segundo apellido:",
      affectedUserDocumentType: "Tipo de documento:",
      affectedUserDocumentNumber: "Número de documento:",
      affectedUserHrId: "SAP ID:",
      affectedUserNSN: "Número de seguridad social:",
      createdBy: "Creado por:",
      incidentDate: "Fecha:",
      incidentHour: "Hora:",
      incidentPlace: "Lugar del incidente:",
      incidentZone: "Zona del incidente:",
      chiefDescription: "Descripción de jefe de emergencia:",
      collaborator1Description: "Descripción de la persona afectada:",
      collaborator2Description: "Descripción del colaborador - 2:",
      actionPlan: "Plan de acción:",
      actionPlanResources: "Recursos plan de acción:",
      mediaResources: "Fotos/videos del incidente:",
      userDescription: "Descripción de colaborador",
    },
    accidentStatus: {
      managedProcess: "Accidente gestionado:",
      relapse: "Recaída",
      status: "Estado del accidente:",
      notQualified: "No clasificado",
      withoutLeave: "Sin baja",
      withLeave: "Con baja",
      referredSPS: "Derivado a SPS",
      notSent: "No enviado a Asepeyo",
      qualifiedByAsepeyo: "Clasificado por Asepeyo",
      actionPlanGenerated: "Plan de acción generado",
      pendingDelivery: "Envio pendiente a SAP",
      sentToSAP: "Enviado a SAP",
      backToWork: "Regresó al trabajo",
      flyerGenerated: "Volante generado",
      closed: "Finalizó",
      stage1: "Etapa 1 - Volante generado",
      stage2: "Etapa 2 - Clasificado por Asepeyo",
      stage3: "Etapa 3 - Gestionado por RRHH",
      stage4: "Etapa 4 - Regresó al trabajo",
      stage5: "Etapa 5 - Finalizó",
      stage3Adm: "Etapa 3 - Gestionado",
      stage4Adm: "Etapa 4 - Regresó",
      stage5Adm: "Etapa 5 - Finalizó",
      DRAFT: "Incompleto",
      SENT: "Pendiente respuesta de Asepeyo",
      NOT_INJURIED: "Sin baja - completado Asepeyo",
      INJURIED_LICENSE_TO_BE_OUT_INSURANCE: "Con baja - completado Asepeyo",
      INJURIED_LICENSE_TO_BE_OUT_OTHER:
        "Con baja otra compañía - completado Asepeyo",
      REFERRED_SS: "Cerrado - Derivado (SPS)",
      RETURNED: "Con baja - completado Asepeyo",
      CLOSED: "Cerrado",
      RELAPSED: "Recaída - completado Asepeyo",
      MANAGED: "Gestionado",
      managed: "Gestionado",
      managedByHR: "Gestionado por RRHH",
    },
    myAccidents: {
      stage1: "Etapa 1:",
      stage2: "Etapa 2:",
      stage3: "Etapa 3:",
      stage4: "Etapa 4:",
      stage5: "Etapa 5:",
      collaboratorInvolved: "Persona involucrada:",
      accidentType: "Tipo de accidente:",
      myAccidents: "Mis accidentes de trabajo",
    },
    myIncidents: {
      collaboratorsInvolved: "Personas involucradas:",
      createdBy: "Creado por:",
      date: "Fecha:",
      hour: "Hora:",
      incidentPlace: "Lugar del incidente:",
      incidentZone: "Zona del incidente:",
      proposedActionPlan: "Plan de acción propuesto:",
      actionPlanResources: "Recursos plan de acción:",
      myIncidents: "Mis incidentes",
    },
    errors: {
      contactAdministrator: "Por favor, contácte con algún administrador",
      noResultsFound: "No se han encontrado resultados",
      fieldRequired: "Este campo es requerido",
      emailValidation: "Debe ser un email decathlon válido",
      email: "Debe ser un email válido",
      phoneValidation: "Debe ser un número de teléfono válido",
      reviewTheForm: "Por favor, revise el formulario",
      labelNotFound: "Etiqueta no encontrada",
      tokenExpired: "El token ha expirado, por favor, ingrese nuevamente",
      draftAccident: "Existe otro accidente creado en estado DRAFT",
      userExistsInDb: "Existe otro usuario con ese ID en la base de datos",
      inputValidation: "Existe un error en algún campo",
      jobValidation: "El puesto de trabajo no existe en la base de datos",
      userDoesNotExists: "El usuario no existe en la base de datos",
      insufficientAccessGrant:
        "No posee los permisos necesarios para realizar la acción",
      typeNotExists: "El valor seleccionado no existe en la base de datos",
      requiredInformation: "Hay información requerida que completar",
      couldntCompleteInformation:
        "No se pudo completar la información, deberá completarla manualmente",
      roleLowerThanInput: "Sólo puede asignar un rol inferior al que posee",
      dateValidation:
        "No es posible crear un accidente en el futuro. Un accidente debería haber ocurrido ahora o en el pasado.",
      idAndNameCannotBeNull: "El id y el nombre no pueden ser nulos",
      existingJob: "Ya existe el perfil de trabajo con ese identificador",
      inexistentJob: "El perfil {job} no existe en la base de datos",
      anotherUserFound: "Otro usuario fue encontrado con la misma información:",
      somethingWentWrong:
        "Algo ha salido mal, por favor, contáctese con algun administrador",
      fax: "El fax debe ser válido",
      number: "El número debe ser válido",
      insuranceCenterAlreadyExists:
        "Ya existe un centro de seguro con ese númeroe de identificación",
      alertInsurance:
        "No existe un centro de seguro vinculado a la tienda, por favor, vincule el centro de seguro con su tienda o seleccione un centro diferente",
      userDoNotHaveId: "El usuario no posee la información necesaria",
    },
    placeHolders: {
      documentType: "Tipo de documento",
      jobSeniority: "Antigüedad",
      contractType: "Tipo de contrato",
      country: "País",
      addUser: "Agregar usuario",
      stores: "Tiendas",
      selectARole: "Seleccione un rol",
      insuranceCenters: "Centros de seguro",
      zone: "Zona",
      autonomousCommunity: "Comunidad autónoma",
    },
    countries: {
      ES: "España",
      IT: "Italia",
      FR: "Francia",
      DK: "Dinamarca",
      PT: "Portugal",
      DE: "Alemania",
      IE: "Irlanda",
      NL: "Paises bajos",
      SE: "Suecia",
      CH: "Suiza",
      GB: "Gran Bretaña",
    },
    toasts: {
      jobProfileCreated: "Perfil de trabajo añadido",
      jobRoleUpdated: "Rol de trabajo actualizado",
      userRoleUpdated: "Rol del usuario actualizado",
      centerCreated: "Centro añadido",
      zoneCreated: "Zona añadida",
      insuranceCenterCreated: "Centro de seguro creado",
      insuranceCenterUpdated: "Centro de seguro actualizado",
      incidentUpdated: "Incidente actualizado",
      accidentUpdated: "Accidente actualizado",
      sitesSynchronized: "Tiendas sincronizadas",
      siteCreated: "Tienda creada",
      siteUpdated: "Tienda actualizada",
      regionUpdated: "Región actualizada",
      autonomousCommunityUpdated: "Comunidad autónoma actualizada",
      userUpdated: "Usuario actualizado",
      markAsManagedSuccess: "Accidente(s) gestionado(s) correctamente",
      markAsReferredSuccess: "Accidente(s) referido(s) correctamente",
      markAsDeletedSuccess: "Accidente(s) borrado(s) correctamente",
      markAsClosedSuccess: "Accidente(s) cerrado(s) correctamente",
      synchronizedAccidents: "Accidentes sincronizados correctamente",
      downloadFlyerSuccess: "Descarga exitosa",
      generalErrorMessage: "Ha habido un error",
      successfullySent: "Se ha enviado correctamente",
      medicalReportUpdated: "Reporte médico actualizado",
      medicalReportCreado: "Reporte médico creado",
      validatedByDirectorSuccess: "Accidente validado correctamente",
      syncDataSuccess: "Sincronización satisfactoria",
      sendAccidentSuccess: "Accidente enviado correctamente",
    },
    asepeyoData: {
      stage: "Parte:",
      medicalReportType: "Tipo de parte:",
      medicalReportCauseType: "Tipo de incidente:",
      forecast: "Pronóstico:",
      medicalReportDate: "Fecha de parte:",
      sickLeaveDate: "Fecha de baja médica:",
      codes: "Códigos:",
      contactWay: "Forma de contacto:",
      injuriedPart: "Parte lesionada:",
      injuryDescription: "Descripción de la lesión:",
      relapse: "Recaída:",
      A: "Regresó al trabajo",
      B: "Lesionado",
      C: "Confirmado",
      M: "Sin causa de baja",
      N: "Puede regresar al trabajo mañana",
    },
    csvHeaders: {
      sap: "SAP",
      accidentType: "Tipo accidente",
      accidentDate: "Fecha accidente",
      leaveDate: "Baja",
      accidentHour: "HORA ACCIDENTE",
      workHour: "HORA DE TRABAJO",
      itineraryAccident: "TRABAJO HABITUAL (ACCIDENTE ITINERE)",
      accidentDescription: "DESCRIPCION DEL ACCIDENTE",
      placeCode: "CODIGO LUGAR",
      workCode: "CODIGO TRABAJO",
      activityCode: "CODIGO ACTIVIDAD",
      activityAgent: "AGENTE ACTIVIDAD",
      deviationCode: "CODIGO DESVIACION",
      triggerCode: "DESENCADENANTE",
      contractCode: "CODIGO CONTACTO",
      lesionAgent: "AGENTE LESION",
      witness: "TESTIGOS",
      witnessDescription: "DESCRIPCION TESTIGO",
      witnessAccidentDescription: "DESCRIPCION DEL ACCIDENTE",
      locationCode: "CODIGO LUGAR (ACCIDENTE ITINERE)",
      trafficAccident: "ACCIDENTE DE TRAFICO",
      KM: "VIA KM",
      other: "OTRO",
      direction: "DIRECCION",
      region: "Comunidad",
      municipality: "MUNICIPIO",
      country: "PAIS",
      siteMatch: "CENTRO COINCIDE",
      belongs: "PERTENECE",
      lesionCode: "FORMA LESION",
      lesionGravityCode: "GRADO LESION",
      bodyPartCode: "PARTE DEL CUERPO",
      assistantType: "TIPO DE ASISTENCIA",
      jobType: "OCUPACION",
      contracted: "CONTRATA",
      template: "PLANTILLA",
      fullName: "NOMBRE",
      position: "CARGO",
      region2: "Region",
      accidentDate2: "FECHA ACCIDENTE",
      accidentEndDate2: "FECHA FIN ACCIDENTE",
      absenteeismCode: "CODIGO ABSENTISMO",
      accidentDate3: "FECHA ACCIDENTE",
      accidentEndDate: "FECHA FIN ACCIDENTE",
      contingency: "CONTINGENCIA",
      collegiateCode: "CODIGO COLEGIADO",
      region3: "REGION",
      reportState: "ESTADO REPORTE",
      accidentId: "ID",
      siteNumber: "Numero del sitio",
      siteName: "Nombre del sitio",
      sitePhone: "Telefono sitio",
      accidentCreator: "Creador del accidente",
      accidentVictimName: "Nombre herido",
      victimDNI: "DNI",
      job: "Puesto",
      seniority: "Antiguedad en la empresa",
      victimSex: "Sexo",
      victimAge: "Edad",
      dayOfWeek: "Dia de la semana",
      hour: "Hora",
      description: "Descripcion",
      accidentPlace: "Lugar del accidente",
      numberOfHours:
        "Cuantas horas llevaba trabajando el colaborador cuando sufre el accidente",
      itinerary: "Accidente in itinere",
      accidentCause: "Causa del dano",
      actionPlan: "Plan de accion",
      basicCause: "Causas basicas",
      workType: "Tarea",
      usualWorkplace: "En otro trabajo",
      riskEvaluated: "Riesgo evaluado",
      accidentGravity: "Gravedad del suceso (Asepeyo)",
      injuriedPart: "Parte del cuerpo danada",
      injuryDescription: "Dano",
    },
  },
  "en-US": {
    app: {
      name: "Decacare",
      error: "404",
      pageNotFound: "Page not found",
      contactAdministrator: "Please, contact with some administrator",
      unexpectedError: "Unexpected error",
      sessionExpired: "Session expired",
      returnLogin: "Return to login",
    },
    breadCrumbs: {
      start: "Home",
      admin: "Admin",
      accident: "Accident",
      incident: "Incident",
      site: "Store",
      profiles: "Profiles",
      centerAndZones: "Centers and zones",
      insuranceCenter: "Insurance centers",
      addCenterAndZones: "Add zones and centers",
      myAccidents: "My accidents",
      myIncidents: "My incidents",
      accidentsIncidentsList: "List of accidents and incidents",
      centerAccidents: "Accidents in the center",
      centerIncidents: "Incidents in the center",
      countryAccidents: "Accidents in the country",
      countryIncidents: "Incidents in the country",
      jobs: "Job profiles",
      myProfile: "My profile",
      accidentManagement: "Accident Management",
    },
    menu: {
      title: "Decacare",
      profile: "Profile",
      admin: "Admin",
      logout: "Logout",
      myProfile: "My profile",
      dataControl: "Details control",
      accidents: "Accidents",
      incidents: "Incidents",
      myPlans: "My plans",
      flyers: "Flyers",
      myAccidents: "My accidents",
      myIncidents: "My incidents",
      myActionPlans: "My action plans",
      myAssinstanceFlyers: "My assistance flyers",
      centerAccidents: "Accidents in the center",
      centerIncidents: "Incidents in the center",
      countryAccidents: "Accidents within the country",
      countryIncidents: "Incidents within the country",
      allThePlans: "All the action plans in my center",
      flyersCenter: "Center flyers",
      data: "Data",
      reportingData: "Data extraction and reports",
      logoutError: "Error when logging out",
      logoutSuccessful: "You have been logout successfully",
      loginAgain: "Redirect to login",
    },
    general: {
      greeting: "Hi {userName}!",
      canCreate: "As {role}, you can create an accident or an incident",
      createEvent: "Create Accident/Incident",
      asAdmin:
        "As administrator, you can watch accidents or incidents in each store.",
      accidentDescription:
        "The employee is injured or unconscious. Depending on the severity of the situation, call 112.",
      incidentDescription:
        "The accident has not yet occurred. It helps to prevent it.",
      missingInfoFields: "There is missing info, please fill it",
    },
    roles: {
      ROL_COLABORATOR: "Collaborator Role",
      ROL_MANAGER_SECTION: "Emergency Role",
      ROL_MANAGER_SECURITY: "Security Role",
      ROL_MANAGER_STORE: "Store Role",
      ROL_MANAGER_COACH: "Coach Role",
      ROL_ADMIN_HR: "Admin Role",
      ROL_ADMIN_PRL: "Technical Role",
    },
    actions: {
      title: "Your action is required",
      subtitle: "Some news requires your attention",
      createDescription: "Create description of accident/incident",
      createDescriptionMobile: "Create description",
      generateActionPlan: "Generate action plan",
      seeActionPlan: "See your action plan",
      connectToSap: "Connect with SAP",
      twoDaysToGenerate: "You have 48 hours to generate",
      twoDaysToCreate: "You have 48 hours to create",
      oneDayLeft: "You have 24 hours to create",
      accidentDraftTitle:
        "There is an accident in DRAFT stage for the selected user",
      accidentDraftDescription:
        "Would you like to continue or create a new one?",
      accidentChangePlaceTitle:
        "The accident have conflicting information for the selected accident place",
      accidentChangePlaceDescription:
        "There is previous information corresponding to another accident site, do you want to delete it and confirm the change?",
      incidentDraftTitle:
        "There is an incident in DRAFT stage for the selected user",
      incidentDraftDescription:
        "Would you like to continue or create a new one?",
      incidentChangePlaceTitle:
        "The incident have conflicting information for the selected incident place",
      incidentChangePlaceDescription:
        "There is previous information corresponding to another incident site, do you want to delete it and confirm the change?",
      incidentActionPlanTitle:
        "There is conflictive information regarding the incident",
      incidentActionPlanDescription:
        "There is previous information corresponding to an accident that prevents adding an action plan, do you want to delete it and confirm the change?",
    },
    status: {
      accidentCreated: "Accident created",
      incidentCreated: "Incident created",
      actionPlanReady: "Action plan ready",
      resolved: "Resolved",
      availableTime: "You have 24 hours to complete",
      available: "Available",
    },
    events: {
      myEvents: "List of your accidents and incidents",
      centerEvents: "List of your accidents and incidents within your center",
      countryEvents: "List of accidents and incidents within the country",
      centerAccidents: "Accidents in my center",
      centerIncidents: "Incidents in my center",
      countryAccidents: "Accidents in the country",
      countryIncidents: "Incidents in the country",
      accident: "Accident",
      incident: "Incident",
    },
    admin: {
      optionYes: "Yes",
      optionNot: "Not",
      title: "Admin desk",
      subtitle:
        "Here you have the option to view at national level all profiles, centers, accidents and incidents.",
      caption: "You can also modify and report accidents.",
      profiles: "Workers profiles",
      jobProfiles: "Jobs profiles",
      modifyProfile: "Change the rol according each profile.",
      modifyJobRole: "Change the rol according each job.",
      centerAndZones: "Center and Zones",
      createAndModifyZones:
        "Create and modify the data of the centers and zones from the country.",
      connection: "Connection",
      SAPconnection: "Communication with SAP",
      accidents: "Accidents",
      searchAndModifyAccidents: "Create, search and edit accidents.",
      incidents: "Incidents",
      searchAndModifyIncidents: "Create, search and edit incidents.",
      SAPcommunication: "Communication with SAP",
      accidentManagement: "Accident management",
      successfullySent: "Sent successfully",
      deliveryFailed:
        "Delivery failed. Try again and if the problem persists, contact support.",
      countryAccidents: "Accidents in the country",
    },
    buttons: {
      validateAccident: "Validate",
      accidentManagement: "Manage",
      enter: "Enter",
      discover: "Discover",
      moreDetails: "More details",
      seeDetails: "See details",
      more: "More",
      home: "Home",
      createAccident: "Create Accident",
      createIncident: "Create Incident",
      selectSeveralStores: "Select several stores",
      selectOneStore: "Select one store",
      modifyRoles: "Edit roles",
      editEmployee: "Edit employees",
      createNew: "Create new",
      modify: "Modify",
      SAPconnection: "Communicate with SAP",
      signDocument: "Sign document",
      image: "Image",
      delete: "Delete",
      startFilling: "Start filling",
      saveAndPreview: "Save and go to preview",
      back: "Back",
      save: "Save",
      next: "Next",
      confirm: "Confirm",
      preview: "Preview",
      send: "Send",
      viewRecord: "View record",
      returnMainPage: "Return to main page",
      apply: "Apply",
      accidentDate: "Accident date",
      modifyRole: "Modify role",
      assignCenters: "Assign centers",
      cancel: "Cancel",
      changeZone: "Change zone",
      changeDetails: "Modify details",
      addRelapse: "Add relapse",
      createCenter: "Create center",
      createZone: "Create zone",
      createActionPlan: "Create action plan",
      addResources: "Add resources",
      incidentDate: "Incident date",
      goToAccident: "Go to accident",
      goToIncident: "Go to incident",
      createDescription: "Create description",
      continue: "Continue",
      completeProfile: "Complete profile",
      addUser: "Add user",
      createJob: "Create job profile",
      search: "Search",
      sendToSAP: "Send to SAP",
      markAsManaged: "Mark as managed",
      moreActions: "More actions",
      referredToSS: "Referred to SS (SPS)",
      markAsClosed: "Mark as closed",
      witnesses: "Witness",
      downloadTxt: "Download medical flyer",
      downloadCsv: "Download csv",
      downloadFlyer: "Download txt",
      register: "Register",
      manage: "Manage",
      syncSites: "Synchronize sites",
      editUser: "Edit user",
      accept: "Accept",
      asepeyoData: "Asepeyo information",
      syncData: "Synchronize data",
      selectAll: "Select all",
      deselectAll: "Deselect todos",
      deleteAccident: "Delete",
      edit: "Edit",
      update: "Update",
      updateMedicalReport: "Update medical report",
      addMedicalReport: "Update medical report",
      add: "Add",
      deleteMultiCenter: "Delete Current Centers",
    },
    filters: {
      filter: "Filter",
      select: "Select",
      order: "Order",
      oldest: "Oldest",
      recent: "Recent",
      searchBy: "Search by code, center or city",
      selectCenter: "Select center",
      sap: "SAP",
      uid: "UID",
      nameProfile: "Profile",
      nif: "NIF",
      nif_nie: "NIF/NIE",
      nie: "NIE",
      email: "Mail",
      completeName: "Name and lastname",
      passport: "Passport",
      dni: "IDN",
      centers: "Centers",
      zones: "Zone",
      searchByReference: "Search by reference number",
      searchByNameOrCode: "Search by code or name",
      searchByAllFields: "Search by ID, involved persons names, UID, SAP o NIF",
      searchByCenterOrCollaborator: "Search by center or collaborator's name",
      unknown: "Unknown",
      partial: "Partial",
      temporal: "Temporal",
      undefined: "Undefined",
      permanent: "Permanent",
      external: "External",
      lessOnemonth: "Less than 1 month",
      oneToThreeMonths: "Between 1 and 3 months",
      threeToSixMonths: "Between 3 and 6 months",
      sixToTwelves: "Between 6 and 12 months",
      greaterThanTwelve: "Greater than 12 months",
      name: "Name",
      surname: "Surname",
      firstSurname: "First surname",
      lastSurname: "Last surname",
      zone: "Zone",
      filterByZone: "Filter by zone/s",
    },
    emptyState: {
      noResultsFound: "No results found",
    },
    accidentForm: {
      witness: "Witness:",
      signManager: "Emergency manager signature*",
      collaboratorSignature: "Collaborator signature",
      managerSignature:
        "The signature will be obligatory on the part of the head of emergency. Sign your name in the space below",
      rememberAlert: "Remember the proper authorities will be notified",
      collaboratorSign: "Collaborator signature:",
      collaboratorValidation:
        "If the collaborator can't sign, please, check the verification. If the Collaborator can sign, click the 'Next' button.",
      collaboratorCantValidate:
        "Collaborator cannot validate or is unable to do so",
      whoIsTheCollaborator: "Who is the injured collaborator?",
      searchCollaborator:
        "Search and select collaborator involved in the accident. If your first or last name does not appear, search using your NIF/NIE, SAP or EMAIL.",
      searchBy: "Search by profile name, name, surname, SAP, NIF or email",
      preview: "Preview",
      checkAndConfirm: "Review and confirm your data before sending",
      collaboratorInvolved: "Collaborator involved",
      whatHappened: "What happened?",
      collaboratorDescription: "Description of injured employee:",
      collaboratorDescriptionAlert:
        "Injured Contributor Description: Collaborators will be allowed to add their descriptions after submitting this form. Note: It will be editable only for 48 hours.",
      managerDescription: "Emergency Chief Description",
      witnessDescription: "Witness description",
      accidentLevel: "Accident level",
      dateAndHour: "Accident date and time:",
      workPlace: "Accident in the usual workplace",
      accidentSite: "Accident site",
      workingDayMissionDisplacement:
        "Displacement during your working day (displacement on mission)",
      inItinereAccident:
        "In itinere: on the move, before or at the end of your working day",
      accidentInOtherCompany:
        "In another center or workplace outside Decathlon",
      workingHoursAccident: "How many hours did you work",
      takingPlaceActivity: "Activity that was taking place?",
      workTypeActivity: "Work type activity",
      basicCause: "Basic cause",
      causeAndEffectAccident: "Cause and fact, how did the accident happen?",
      registeredAccident: "You have already registered the accident!",
      selectCenter: "Select center",
      healthInsuranceCenterLinked: "Health insurance center linked",
      asepeyoCenter: "Asepeyo center",
      selectAsepeyoCenter: "Select Asepeyo center",
      collaboratorAllowToAddDescription:
        "Contributors will be allowed to add their descriptions after submit this form.",
      editableNote: "Note: It will be editable only for 48 hours.",
      emergencyChiefDescription: "Emergency Chief Description*",
      howSerious: "How serious do you consider the accident?",
      accidentDateHour: "Accident date and hour",
      date: "Date",
      hour: "Hour",
      intervals: "Intervals",
      calendar: "Calendar",
      today: "Today",
      yesterday: "Yesterday",
      storeZone: "Store area where the accident occurred",
      wareHouseZone: "Warehouse zone",
      centralServices: "Central services zone",
      kuikma: "Kuikma zone",
      conceptionCenter: "Conception center",
      missionDisplacement: "Displacement during your working day",
      decathlonCenter: "Decathlon center",
      store: "Store",
      inItinere:
        "In Itinere: on the move, before starting or at the end of your working day",
      inItinereAlert:
        "Accident suffered by the worker on the usual route to get to work or return home.",
      address: "Address and detailed description of the accident",
      inItinerePostalCode: "Postal code of the accident place",
      inItinerePlace: "Place of the accident (displacement)",
      companyName: "Company name",
      companyCIF: "Company CIF",
      workingHours: "How many hours has the employee been working?",
      others: "Others",
      causeAndFact: "Cause and fact, how did the accident occur?",
      alertText: "Send notification to the concerned authorities",
      step1: "Collaborator involved",
      step2: "Fill in the accident",
      step3: "Preview",
      step4: "Signature",
      step5: "Done!",
      dateOfTheAccident: "Accident date:",
      hourOfTheAccident: "Accident hour:",
      nameOfTheCompany: "Company name:",
      cifOfTheCompany: "Company CIF:",
      asepeyoNotification: "Sent to the insurance center",
      emailSentSuccesfully: "Email sent successfully",
      emailSentUnuccesfully:
        "There was a problem sending the email, if the problem persists, please contact an administrator",
    },
    incidentForm: {
      collaboratorsInvolved: "Involved collaborators",
      collaboratorInvolved: "Involved collaborator",
      incidentAlert:
        "An incident is considered a situation that occurs during work activities which, without causing injury, has the potential to cause harm.",
      searchCollaborator:
        "Search and select collaborator involved in the accident. If your first or last name does not appear, search using your NIF/NIE, SAP or EMAIL.",
      step1: "Involved collaborator",
      step2: "Fill in the incident",
      step3: "Action plan",
      step4: "Preview",
      step5: "Done!",
      registeredIncident: "You have already submitted the incident!",
      searchBy: "Search by profile name, name, surname, SAP, NIF or email",
      whatHappened: "What happened?",
      collaboratorAllowToAddDescription:
        "Collaborators will be allowed to add their descriptions after submitting this form.",
      editableNote: "Note: It will be editable only for 48 hours.",
      emergencyChiefDescription: "Incident description",
      incidentDateHour: "Incident date and time",
      date: "Date",
      hour: "Time",
      workPlace: "Place where the incident occurred",
      storeZone: "Place in the store where the incident occurred",
      wareHouseZone: "Place in the warehouse where the incident occurred",
      centralServices: "Place in central services where the incident occurred",
      kuikma: "Place in Kuikma where the incident occurred",
      conceptionCenter:
        "Place in the conception center where the incident occurred",
      actionPlan: "Action plan",
      actionPlanSuggest:
        "Can any aspect of the environment be improved so that it does not pose a risk?",
      preview: "Preview",
      checkAndConfirm: "Check and confirm the data before submitting",
      incidentDescription: "Description of the incident that occurred",
      incidentSite: "Site of the incident",
      actionPlanProposed: "Proposed action plan",
      sendActionPlan: "Send the action plan to the collaborator",
      sendActionPlanDescription:
        "(The store director will also receive an email with what happened and the developed action plan)",
    },
    formOptions: {
      unknownTitle: "0. Unknown",
      unknownContent: "Accident type is unknown.",
      mildTitle: "1. Mild (without/with sick leave)",
      mildContent:
        "The person is conscious and can move by himself/herself. For example cuts, falls, and blows without excessive severity.",
      seriousTitle: "2. Serious",
      seriousContent:
        "The person is not conscious and cannot move on their own. The employee has significant injuries and needs medical assistance at the scene of the accident and transfer to a hospital. For example, broken bones or tendons.",
      verySeriousTitle: "3. Very serious",
      verySeriousContent:
        "The person is not conscious and cannot move on his or her own. For example Amputation or loss of consciousness (unconsciousness).",
      deadlyTitle: "4. Fatal accidents",
      deadlyContent: "Personal injuries cause the death of the worker",
      accidentSite1: "Accident in the usual workplace",
      accidentSite2:
        "In Itinere: on the move, before starting or at the end of your working day",
      accidentSite3:
        "Displacement to another Decathlon center other than the usual one",
      accidentSite4: "In another company",
      zone1: "Store",
      zone2: "Logistics center / Service Center",
      zone3: "Central services",
      zone4: "Conception center",
      journey1: "When going to work",
      journey2: "Coming back from work",
      storeZone1: "Dock/Reserve",
      storeZone2: "Common rooms",
      storeZone3: "Parking",
      storeZone4: "Carrying out sports activity (indoor or outdoor)",
      storeZone5: "CGBT, generator set room or pump room",
      storeZone6: "Commercial zone",
      wareHouseZone1: "Loading and unloading docks",
      wareHouseZone2: "Perimeter corridor",
      wareHouseZone3: "Parking",
      wareHouseZone4: "Heteroclite corridor",
      wareHouseZone5: "Transit zone",
      wareHouseZone6: "Offices",
      wareHouseZone7: "Technical rooms",
      wareHouseZone8: "Common rooms",
      wareHouseZone9: "Sports activities",
      wareHouseZone10: "Regional workshop",
      wareHouseZone11: "Merchandise consolidation zone",
      wareHouseZone12: "Picking zone",
      centralServicesZone1: "Parking",
      centralServicesZone2: "Stairs",
      centralServicesZone3: "Lift",
      centralServicesZone4: "Meeting rooms",
      centralServicesZone5: "Floor 0",
      centralServicesZone6: "Floor 1",
      centralServicesZone7: "Floor 2",
      centralServicesZone8: "Floor 3",
      centralServicesZone9: "Common zones",
      kuikmaZone1: "Office",
      kuikmaZone2: "Warehouse",
      kuikmaZone3: "Test",
      conceptionCenterZone1: "Office",
      conceptionCenterZone2: "Warehouse",
      conceptionCenterZone3: "Test",
      workedHours1: "1 Hour",
      workedHours2: "2 Hours",
      workedHours3: "3 Hours",
      workedHours4: "4 Hours",
      workedHours5: "5 Hours",
      workedHours6: "6 Hours",
      workedHours7: "7 Hours",
      workedHours8: "8 Hours",
      workedHours9: "9 Hours",
      workedHours10: "10 Hours",
      workedHours11: "11 Hours",
      workedHours12: "12 Hours",
      workedHours13: "> 12 Hours",
      activity1: "Using wheelbarrows",
      activity2: "Manual pallet truck",
      activity3: "Using workshop tools",
      activity4: "Making a move",
      activity5: "Facing",
      activity6: "Picking",
      activity7: "Walking, going up or down stairs",
      activity8: "Others",
      cause1: "Fire",
      cause2: "Electrical contact",
      cause3: "Material colliding with the worker",
      cause4: "Loss of control of machinery",
      cause5: "Mugging assault",
      cause6: "Get caught",
      cause7: "Collaborator fall",
      cause8: "Technical rooms",
      cause9: "Cut",
      cause10: "Others",
      center1: "Send to your Asepeyo center",
      center2:
        "Send to another center in Asepeyo different from the one that corresponds to my center",
      center3: "Accident victim does not go to the medical center",
      workType1: "Picking reapro",
      workType2: "Upload or Download",
      workType3: "Inventory / stock management",
      workType4: "Store",
      workType5: "Sport activities",
      workType6: "Others, in itinere, other company",
      returned: "End of sick leave",
      injuried: "Accident with sick leave",
      confirmed: "Confirmation part",
      doesNotCauseSickLeave: "Accident without sick leave",
      ableToWorkTomorrow: "Repose",
      workAccident: "Work accident",
      professionalSick: "Professional sick",
      slight: "Slight",
      serious: "Serious",
      verySerious: "Very serious",
      mortal: "Mortal",
      basicCause1: "Overexertion due to manual handling of loads",
      basicCause2: "Sports physical activity",
      basicCause3: "Fall to the same or different level",
      basicCause4: "Smash or punch",
      basicCause5: "Cut",
      basicCause6: "In other company",
      basicCause7: "In itinere",
      basicCause8: "Relapse",
      basicCause9: "Contact with harmful or toxic substances/poisoning",
      basicCause10: "On Mission",
      basicCause11: "Run Over",
      basicCause12: "Entrapment",
      basicCause13: "Burns",
    },
    timeLine: {
      flyerGeneration: "Flyer generation",
      asepeyoClasification: "Asepeyo clasification",
      actionPlan: "Action plan",
      backToWork: "Back to work",
      sendToSAP: "Send to SAP",
      completed: "Completed",
      step1: "Step 1",
      step2: "Step 2",
      step3: "Step 3",
      step4: "Step 4",
      step5: "Step 5",
      managed: "Managed",
      managedByHR: "Managed por HR",
      returned: "returned",
    },
    adminProfile: {
      id: "Identification number:",
      name: "Name:",
      collaboratorName: "Collaborator name:",
      collaboratorFirstSurname: "Collaborator Surname 1:",
      collaboratorSecondSurname: "Collaborator Surname 2:",
      profile: "Profile:",
      email: "Mail:",
      phoneNumber: "Phone number:",
      role: "Role:",
      workCenter: "Working center:",
      job: "Working position:",
      chief: "Manager:",
      sap: "SAP:",
      contractType: "Contract type:",
      accidentsIncidentsInvolved: "Accidents and incidents involved:",
      changeRole: "Change role",
      assignCenters: "Assign centers",
      assignedCenters: "Assigned Centers",
      currentRole: "Current role:",
      newRole: "New role:",
      selectNewRole: "Select new role",
      currentSite: "Current sites:",
      newSite: "New sites:",
      documentNumber: "Document Number:",
      documentType: "Document Type:",
      missingInformation: "Missing information",
      editProfile: "Change user details",
    },
    rolesOption: {
      role1: "01 - Collaborator Role",
      role2: "02 - Emergency Role",
      role3: "03 - Security Role",
      role4: "04 - Store Role",
      role5: "05 - Coach Role",
      role6: "06 - Admin Role",
      role7: "07 - Technical Role",
      undefined: "Role undefined",
    },
    adminCenter: {
      id: "Identification number:",
      zone: "Zone:",
      stores: "Stores:",
      name: "Nombre:",
      description: "Description",
      centerNumber: "Center Number:",
      centerName: "Center Name:",
      phoneNumber: "Phone:",
      phone: "Phone:",
      accountNumber: "Listing Account Code:",
      address: "Address:",
      region: "Region:",
      decathlonRegion: "Decathlon Region:",
      doctorName: "Doctor Name:",
      asepeyoAddress: "Asepeyo Address:",
      zipAsepeyo: "Asepeyo ZIP:",
      asepeyoEmail: "Asepeyo Email:",
      asepeyoPhone: "Asepeyo Phone:",
      asepeyoFax: "Asepeyo Fax:",
      changeCenterZone: "Change the center zone",
      currentZone: "Current zone:",
      newZone: "New zone:",
      selectNewZone: "Select a new zone",
      changeCenterName: "Change center details",
      addZoneAndCenter: "Add Zone/Center",
      zoneName: "Zone name:",
      changeJobRoles: "Change job profiles",
      createJob: "Create job profile",
      jobId: "Job profile ID",
      jobName: "Job profile name",
      jobRole: "Job profile role",
      endDate: "End date:",
      email: "Email:",
      complement: "Additional address information:",
      zipCode: "Zip code:",
      city: "City:",
      country: "Country:",
      linkedSite: "Linked site:",
      linkedInsuranceCenter: "Linked insurance center:",
      siteAddress: "Site address:",
      siteComplement: "Site complement:",
      siteZipCode: "Site zipcode:",
      siteCity: "Site city:",
      siteCountry: "Site country:",
      startDate: "Start date:",
      stateProcess: "State of the process:",
      autonomousCommunity: "Autonomous community:",
      faxNumber: "Fax:",
      insuranceCenters: "Insurance centers:",
      missingInformation: "Missing information",
      zoneUndefined: "Region undefined",
      staffTemplate: "Staff Template:",
    },
    centersCard: {
      title: "Centers",
      description:
        "Watch and change centers. You can search by center name or code or use the zones filter.",
      searchByZones: "Search by zone/s",
      searchByCodeOrName: "Search by code or center name",
      addCenter: "Add Center",
      addZone: "Add Zone",
      insuranceCenter: "Insurance centers",
      insuranceDescription:
        "Watch and change centers. You can search by center name or use the sites filter.",
      centerList: "Centers list",
    },
    insuranceCenter: {
      id: "Identification number",
      name: "Name",
      phone: "Phone",
      email: "Email",
      address: "Address",
      complement: "Complement",
      zipCode: "Zip code",
      city: "City",
      country: "Country",
      fax: "Fax",
      sites: "Store",
    },
    profilesCard: {
      title: "Profiles",
      description: "Watch and change profiles. You can search or use filters.",
      searchByCodeOrName: "Search by Name, UID, SAP or NIF",
      changeProfileInfo: "Change profile details",
    },
    jobsCard: {
      title: "Job profiles",
      description: "Watch and change the roles related to jobs.",
      searchByName: "Search by job name",
    },
    userForm: {
      involvedPersonData: "Data of the person involved",
      reviewProfileInfo: "Complete the collaborator's information",
      name: "Name of person involved:",
      firstSurname: "Surname 1 of the person involved:",
      secondSurname: "Surname 2 of the person involved:",
      id: "Center number:",
      birthDate: "Date of birth:",
      profile: "Profile:",
      email: "Email:",
      phone: "Phone number:",
      role: "Role:",
      site: "Work center",
      job: "Job:",
      manager: "Manager",
      managerProfile: "Manager profile:",
      managerName: "Manager name:",
      managerSurname: "Manager surname:",
      managerEmail: "Manager email:",
      hrId: "SAP:",
      contractType: "Contract type:",
      documentNumber: "Document number:",
      documentType: "Document type:",
      accidentsIncidentsInvolved: "Accidents and incidents you've been in:",
      startDate: "Start date in the company:",
      jobSeniority: "Seniority in the job position:",
      universe: "Universe:",
      address: "Address:",
      complement: "Complement:",
      city: "City:",
      zipCode: "Zip Code:",
      country: "Country:",
      nationalSecurityNumber: "Social Security Number:",
      siteName: "Name:",
      siteCode: "Store id:",
      sitePhone: "Shop phone:",
      siteAddress: "Street:",
      siteComplement: "Complement:",
      siteCity: "City",
      siteZip: "Zip Code:",
      siteCountry: "Country:",
      costCenter: "Quotation account code:",
      seniorityDate: "Seniority:",
    },
    axidentDetails: {
      accidentDetails: "Accident details",
      flyer: "Flyer:",
      accidentType: "Accident type:",
      notQualified: "It is not classified by Asepeyo yet.",
      accidentLeave: "Accident with sick leave",
      actionPlan: "Action plan",
      actionPlanResources: "Action plan resources:",
      actionPlanNotCreated: "It is not created yet",
      sapConection: "SAP connection:",
      pendingShipmentSAP: "Pending shipment to sap",
      sapNotConected: "It is not connected yet. You can manually connect.",
      stage1: "Stage 1",
      stage2: "Stage 2",
      stage3: "Stage 3",
      stage4: "Stage 4",
      actionPlanGenerated: "Generated action plan",
      accidentSeverity: "Accident severity:",
      accidentSeverity1: "1 - mild",
      accidentSeverity2: "2 - severous",
      accidentSeverity3: "3 - very severous",
      accidentSeverity4: "4 - deathly",
      collaboratorsInvolved: "Collaborator involved:",
      accidentDate: "Accident date:",
      accidentHour: "Accident hour:",
      accidentPlace: "Accident place:",
      chiefDescription: "Accident description (emergency chief):",
      collaboratorDescription: "Accident description (collaborator):",
      missionDisplacement: "Mission displacement:",
      workingHours: "How many hours has been working:",
      activity: "Activity was taking place:",
      causeAndFacts: "How did it happen:",
      workTypes: "work type:",
      workPlace: "Work place",
      collaboratorData: "collaborator data",
      investigation: "Investigation",
      signatures: "Signatures",
      asepeyoData: "Asepeyo data",
      asepeyoDataFirstPart: "Asepeyo data - 1st part",
      asepeyoDataSecondPart: "Asepeyo data - 2nd part",
      asepeyoDataPart: "Asepeyo data - part",
      doYouWantToAddMedicalReport: "Do you want to add medical report data?",
      validationInformation1: "Validation by the leader is necessary.",
      validationInformation2:
        "Remember that before validating, the action plan must be completed.",
      accidentValidatedByLeader: "Accident validated by leader",
    },
    actionPlan: {
      actionPlanAccident: "Action plan - Accident",
      actionPlanRecomended:
        "What is the recommended action plan for the accident?",
      actionPlanResources:
        "Resources that you consider appropriate as an action plan",
    },
    incidentDetails: {
      incidentDetails: "Incident details",
      personalInvolved: "Personal involved:",
      createdBy: "Created by:",
      incidentDate: "Incident date:",
      incidentHour: "Incident hour:",
      incidentPlace: "Incident place:",
      incidentZone: "Incident zone:",
      incidentChiefDescription: "Incident description (emergency chief):",
      incidentCollaboratorDescription: "Incident description (collaborator):",
      actionPlan: "Action plan:",
      actionPlanResources: "Action plan resources:",
      incidentMedia: "Incident pictures/video:",
    },
    editAccident: {
      editAccident: "Edit accident",
      accidentSeverity: "Accident severity:",
      collaboratorInvolved: "Collaborator involved:",
      affectedUserId: "Id",
      affectedUserName: "Name:",
      affectedUserFirstSurname: "First surname:",
      affectedUserLastSurname: "Last surname:",
      affectedUserDocumentType: "Document type:",
      affectedUserDocumentNumber: "Document number:",
      affectedUserHrId: "SAP ID:",
      affectedUserNSN: "Social Security number:",
      chiefDescription: "Emergency chief description:",
      collaboratorDescription: "Involved collaborator description:",
      workingHours: "How many hours has been working:",
      accidentDate: "Date:",
      accidentHour: "Hour:",
      accidentPlace: "Accident place:",
      accidentZone: "Accident zone:",
      missionDisplacement: "Mission displacement:",
      activity: "Activity was taking place:",
      causeAndFacts: "How did it happen:",
      workType: "Work type:",
      occurenceAddress: "Accident address:",
      site: "Site:",
      sitePhone: "Site phone number:",
      externalSiteName: "External company - name:",
      externalSiteCode: "External company - CIF:",
      emergencyChiefSignature: "Emergency boss signature:",
      userInvolvedSignature: "Involved person signature:",
      createRelapse: "Create medical report",
      updateMedicalReport: "Update medical report",
      basicCause: "Basic Cause",
      witnessDescription: "Witness description",
    },
    editIncident: {
      editIncident: "Edit incident",
      collaboratorsInvolved: "Collaborator/s involved",
      affectedUserName: "Name:",
      affectedUserFirstSurname: "First surname:",
      affectedUserLastSurname: "Last surname:",
      affectedUserDocumentType: "Document type:",
      affectedUserDocumentNumber: "Document number:",
      affectedUserHrId: "SAP ID:",
      affectedUserNSN: "Social Security number:",
      createdBy: "Created by:",
      incidentDate: "Date:",
      incidentHour: "Hour:",
      incidentPlace: "Incident place:",
      incidentZone: "Incident zone:",
      chiefDescription: "Emergency chief description:",
      collaborator1Description: "Affected person description:",
      collaborator2Description: "Collaborator description - 2:",
      actionPlan: "Action plan:",
      actionPlanResources: "Action plan resources:",
      mediaResources: "Incident Pictures/video:",
      userDescription: "User Description",
    },
    accidentStatus: {
      managedProcess: "Managed accident:",
      relapse: "Relapse",
      status: "Accident Status:",
      notQualified: "Not qualified",
      withoutLeave: "Without leave",
      withLeave: "With leave",
      referredSPS: "Referred to SPS",
      notSent: "Not sent to Asepeyo",
      qualifiedByAsepeyo: "Qualified by Asepeyo",
      actionPlanGenerated: "Action plan generated",
      pendingDelivery: "Pending shipment to sap",
      sentToSAP: "Sent to SAP",
      backToWork: "Back to work",
      closed: "Closed",
      flyerGenerated: "Flyer generated",
      stage1: "Stage 1 - Flyer generated",
      stage2: "Stage 2 - Asepeyo qualified",
      stage3: "Stage 3 - Action plan generated",
      stage4: "Stage 4 - Back to work",
      DRAFT: "Draft / Incomplete",
      SENT: "Pending response from Asepeyo",
      NOT_INJURIED: "Without leave - completed by the mutua",
      INJURIED_LICENSE_TO_BE_OUT_INSURANCE:
        "With leave - completed by the mutua",
      INJURIED_LICENSE_TO_BE_OUT_OTHER:
        "With leave other company - completed by the mutua",
      REFERRED_SS: "Closed - Referred to Social Security (SPS)",
      RETURNED: "With leave - completed by the mutua",
      CLOSED: "Closed",
      RELAPSED: "Relapse - completed by the mutua",
      MANAGED: "Managed",
      managedByHR: "Managed by HR",
    },
    myAccidents: {
      stage1: "Stage 1:",
      stage2: "Stage 2:",
      stage3: "Stage 3:",
      stage4: "Stage 4:",
      collaboratorInvolved: "Person involved:",
      accidentType: "Accident type:",
      myAccidents: "My work accidents",
    },
    myIncidents: {
      collaboratorsInvolved: "Persons involved:",
      createdBy: "Created by:",
      date: "Date:",
      hour: "Hour:",
      incidentPlace: "Incident place:",
      incidentZone: "Incident zone:",
      proposedActionPlan: "Proposed action plan:",
      actionPlanResources: "Action plan resources:",
      myIncidents: "My incidents",
    },
    errors: {
      contactAdministrator: "Please, contact with some administrator",
      noResultsFound: "No results has been found",
      fieldRequired: "This field is required",
      emailValidation: "Must be a valid decathlon email",
      email: "Debe ser un email válido",
      phoneValidation: "Must be a valid phone number",
      reviewTheForm: "Please, review the form",
      labelNotFound: "Label not found",
      tokenExpired: "Token expired, please, login again",
      draftAccident: "There is an other accident created in DRAFT status",
      userExistsInDb: "There is another user with that ID within the DB",
      inputValidation: "There is an error in some textfield",
      jobValidation: "The job does not exists within the DB",
      userDoesNotExists: "The user does not exists within the DB",
      insufficientAccessGrant: "Insufficient grant access to the resource",
      typeNotExists: "The selected value does not exists in the DB",
      requiredInformation: "There is required information to complete",
      couldntCompleteInformation:
        "Could not complete the fetched information, should be completed by hand",
      roleLowerThanInput: "You can only assign a role below the one you own",
      dateValidation:
        "It is not possible create an accident to the future. An accident should be occured now or in the past.",
      idAndNameCannotBeNull: "The identificator and name can not be null",
      existingJob: "There is another job profile with that ID",
      inexistentJob: "Job {job} not found within the database",
      anotherUserFound:
        "It was found another user with the following information:",
      somethingWentWrong:
        "Something went wrong, please, contact with some administrator",
      fax: "Invalid fax number",
      number: "Invalid number",
      insuranceCenterAlreadyExists:
        "There is another insurance center with that identifier.",
      alertInsurance:
        "There is no insurance center bounded to the store, please, bound the insurance center with the store or select a different center",
      userDoNotHaveId: "The user do not possess the information to continue",
    },
    placeHolders: {
      documentType: "Document type",
      jobSeniority: "Seniority",
      contractType: "Contract type",
      country: "Country",
      stores: "Stores",
      addUser: "Add user",
      selectARole: "Select a role",
      insuranceCenters: "Insurance centers",
      zone: "Zone",
      autonomousCommunity: "Autonomous community",
    },
    countries: {
      ES: "Spain",
      IT: "Italy",
      FR: "France",
      DK: "Denmark",
      PT: "Portugal",
      DE: "Germany",
      IE: "Ireland",
      NL: "Netherlands",
      SE: "Sweden",
      CH: "Switzerland",
      GB: "Great Britain",
    },
    toasts: {
      jobProfileCreated: "Job profile created",
      jobRoleUpdated: "Job role updated",
      userRoleUpdated: "User role updated",
      centerCreated: "Center created",
      zoneCreated: "Zone created",
      insuranceCenterCreated: "Insurance center created",
      insuranceCenterUpdated: "Insurance center updated",
      incidentUpdated: "Incident updated",
      accidentUpdated: "Accident actualizado",
      sitesSynchronized: "Sites synchronized",
      siteCreated: "Site created",
      siteUpdated: "Site updated",
      regionUpdated: "Region updated",
      autonomousCommunityUpdated: "Autonomous community updated",
      userUpdated: "User updated",
      markAsManagedSuccess: "Accident(s) correctly managed",
      markAsReferredSuccess: "Accident(s) correctly referred",
      markAsDeletedSuccess: "Accident(s) correctly deleted",
      markAsClosedSuccess: "Accident(s) correctly closed",
      synchronizedAccidents: "Accidents timed correctly",
      downloadFlyerSuccess: "Download success",
      generalErrorMessage: "There has been an error",
      successfullySent: "It has been sent successfully",
      medicalReportUpdated: "Medical report updated",
      medicalReportCreado: "Medical Report created",
      validatedByDirectorSuccess: "Validation successful",
      syncDataSuccess: "Synchro success",
      sendAccidentSuccess: "Send Accident success",
    },
    asepeyoData: {
      stage: "Medical report:",
      medicalReportType: "Type of part:",
      medicalReportCauseType: "Incident type:",
      forecast: "Forecast:",
      medicalReportDate: "Part date:",
      sickLeaveDate: "Leave date:",
      codes: "Codes:",
      contactWay: "Contact way:",
      injuriedPart: "Injured part:",
      injuryDescription: "Injury description:",
      relapse: "Relapsed:",
      A: "Returned",
      B: "Injuried",
      C: "Confirmed",
      M: "Does Not Cause Sick Leave",
      N: "Able to Work Tomorrow",
    },
    csvHeaders: {
      sap: "SAP",
      accidentType: "ACCIDENT TYPE",
      accidentDate: "ACCIDENT DATE",
      leaveDate: "LEAVE DATE",
      accidentHour: "ACCIDENT HOUR",
      workHour: "WORK HOUR",
      itineraryAccident: "USUAL WORK (ITINERE ACCIDENT)",
      accidentDescription: "ACCIDENT DESCRIPTION",
      placeCode: "PLACE CODE",
      workCode: "WORK CODE",
      activityCode: "ACTIVITY CODE",
      activityAgent: "ACTIVITY AGENT",
      deviationCode: "DEVIATION CODE",
      triggerCode: "TRIGGER CODE",
      contractCode: "CONTACT CODE",
      lesionAgent: "LESION AGENT",
      witness: "WITNESS",
      witnessDescription: "WITNESS DESCRIPTION",
      witnessAccidentDescription: "ACCIDENT DESCRIPTION",
      locationCode: "LOCATION CODE (ITINERE ACCIDENT)",
      trafficAccident: "TRAFFIC ACCIDENT",
      KM: "KM WAY",
      other: "OTHER",
      direction: "ADDRESS",
      region: "REGION",
      municipality: "MUNICIPALITY",
      country: "COUNTRY",
      siteMatch: "SITE MATCH",
      belongs: "BELONGS",
      lesionCode: "LESION",
      lesionGravityCode: "LESION GRADE",
      bodyPartCode: "BODY PART CODE",
      assistantType: "ASSISTANT TYPE",
      jobType: "JOB TYPE",
      contracted: "HIRES",
      template: "TEMPLATE",
      fullName: "NAME",
      position: "POSITION",
      region2: "REGION",
      accidentDate2: "ACCIDENT DATE",
      accidentEndDate2: "ACCIDENT END DATE",
      absenteeismCode: "ABSENTEEISM CODE",
      accidentDate3: "ACCIDENT DATE",
      accidentEndDate: "ACCIDENT END DATE",
      contingency: "CONTINGENCY",
      collegiateCode: "COLLEGIATE CODE",
      region3: "REGION",
      reportState: "REPORT STATE",
      accidentId: "ID",
      siteNumber: "Site number",
      siteName: "Site Name",
      sitePhone: "Site phone",
      accidentCreator: "Accident creator",
      accidentVictimName: "accidentVictimName",
      victimDNI: "victimDNI",
      job: "job",
      seniority: "seniority",
      victimSex: "victimSex",
      victimAge: "victimAge",
      dayOfWeek: "dayOfWeek",
      hour: "hour",
      description: "description",
      accidentPlace: "accidentPlace",
      numberOfHours: "numberOfHours",
      itinerary: "itinerary",
      accidentCause: "accidentCause",
      actionPlan: "actionPlan",
      basicCause: "basicCause",
      workType: "workType",
      usualWorkplace: "usualWorkplace",
      riskEvaluated: "riskEvaluated",
      accidentGravity: "accidentGravity",
      injuriedPart: "injuriedPart",
      injuryDescription: "injuryDescription",
    },
  },
};

export default messages;
