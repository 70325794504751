// export const myMutation = (state) => { do something }

// Corresponding with AXIDENTPLUS
export const setSites = (state, sites) => {
  state.sites = sites;
};

export const setMultiSites = (state, multiSites) => {
  state.multiSites = multiSites;
};

export const setSite = (state, site) => {
  state.site = site;
};

// Corresponding with MASTERDATA
export const setStores = (state, stores) => {
  state.stores = stores;
};

export const setStore = (state, store) => {
  state.store = store;
};

export const setCheckedSites = (state, sites) => {
  state.checkedSites = sites;
};

export const setSitesPagination = (state, pagination) => {
  state.sitesPagination = pagination;
};

export const setStoresPagination = (state, pagination) => {
  state.storesPagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};

export const addNewSite = (state, site) => {
  state.sites = [site, ...state.sites];
};
