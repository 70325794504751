import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import VCalendar from "v-calendar";
import { vue3Debounce } from "vue-debounce";
import { clickOutside } from "./plugins/directives";
import { defineRule } from "vee-validate";
import "../node_modules/nprogress/nprogress.css";

import {
  required,
  decathlonEmail,
  phoneNumber,
  siteEmail,
  fax,
  email,
  numberText,
} from "./plugins/vee-validate";

// vee-validate validations
defineRule("required", required);
defineRule("decathlonEmail", decathlonEmail);
defineRule("phoneNumber", phoneNumber);
defineRule("siteEmail", siteEmail);
defineRule("fax", fax);
defineRule("email", email);
defineRule("numberText", numberText);

// Css
import "./assets/scss/styles.scss";
import "v-calendar/style.css";

// vtmn
import "@vtmn/css/dist/index.css";
import "@vtmn/vue/dist/style.css";
import "@vtmn/css-design-tokens/dist/index.css";
import "@vtmn/icons/dist/vitamix/font/vitamix.css";

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VCalendar)
  .directive("debounce", vue3Debounce({ lock: true }))
  .directive("click-outside", clickOutside)
  .mount("#app");
