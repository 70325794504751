const accidentStatus = {
  draft: "DRAFT",
  sent: "SENT",
  notSent: "NOT_SENT",
  notInjuried: "NOT_INJURIED",
  injuriedLicenseInsurance: "INJURIED_LICENSE_TO_BE_OUT_INSURANCE",
  injuriedLicenseOthers: "INJURIED_LICENSE_TO_BE_OUT_OTHER",
  referredSS: "REFERRED_SS",
  returned: "RETURNED",
  relapse: "RELAPSE",
  closed: "CLOSED",
  managed: "MANAGED",
};

export default accidentStatus;
