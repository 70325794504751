// export const myAction = async ({commit}) => {}

import axidentApi from "../../api/axidentApi";
import { accidentStatus, eventType } from "@/enums";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const setAccidents = async (
  { commit },
  selectedPage = 0,
  pageSize = 10
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/accidents?type=${eventType.accident}&status=${accidentStatus.sent}&status=${accidentStatus.notSent}&status=${accidentStatus.notInjuried}&status=${accidentStatus.injuriedLicenseInsurance}&status=${accidentStatus.injuriedLicenseOthers}&status=${accidentStatus.referredSS}&status=${accidentStatus.returned}&status=${accidentStatus.closed}&status=${accidentStatus.managed}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setAccidents", accidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateAccident = async ({ commit, state }, payload) => {
  const { id, data } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch(`/accidents/${id}`, data);

    // Create new array with the updated values
    const updatedAccidents = state.accidents.map((accident) => {
      if (accident.id === resp.id) {
        return resp;
      }
      return accident;
    });
    // Commit the updated state to vuex to update the view
    commit("setAccidents", updatedAccidents);
    commit("setAccident", resp.data);
    return resp.data;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateMedicalReport = async ({ commit }, payload) => {
  const { accidentId, medicalReportData, medicalReportDate } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch(
      `/accidents/${accidentId}/medical-reports/${medicalReportDate}`,
      medicalReportData
    );
    return resp.data;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const addMedicalReport = async ({ commit }, payload) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.post(`/hr/create/medicalReport`, payload);
    return resp.data;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateMyAccident = async ({ commit, state }, payload) => {
  const { id, data } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch(`/users/me/accidents/${id}`, data);

    // Create new array with the updated values
    const updatedAccidents = state.accidents.map((accident) => {
      if (accident.id === resp.id) {
        return resp;
      }
      return accident;
    });
    // Commit the updated state to vuex to update the view
    commit("setAccidents", updatedAccidents);
    commit("setAccident", resp.data);
    return resp.data;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setMyAccidents = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/users/me/accidents?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setAccidents", accidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSiteAccidents = async (
  { commit },
  { id, selectedPage = 0, pageSize = 1000 }
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/accidents?type=${eventType.accident}&status=${accidentStatus.sent}&status=${accidentStatus.notSent}&status=${accidentStatus.notInjuried}&status=${accidentStatus.injuriedLicenseInsurance}&status=${accidentStatus.injuriedLicenseOthers}&status=${accidentStatus.referredSS}&status=${accidentStatus.returned}&status=${accidentStatus.closed}&status=${accidentStatus.managed}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );

    const siteAccidents = accidents.filter((a) => a.site?.id === id);

    commit("setAccidents", siteAccidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSiteEvents = async (
  { commit },
  { id = "", selectedPage = 0, pageSize = 1000 }
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/accidents?type=${eventType.accident}&status=${accidentStatus.sent}&status=${accidentStatus.notSent}&status=${accidentStatus.notInjuried}&status=${accidentStatus.injuriedLicenseInsurance}&status=${accidentStatus.injuriedLicenseOthers}&status=${accidentStatus.referredSS}&status=${accidentStatus.returned}&status=${accidentStatus.closed}&status=${accidentStatus.managed}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    if (id == null || id == undefined) {
      const siteEvents = accidents;
      commit("setAccidents", siteEvents);
      return;
    } else {
      const siteAccidents = accidents.filter((a) => a.site?.id === id);

      const siteEvents = siteAccidents;

      commit("setAccidents", siteEvents);
    }
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setMyEvents = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/users/me/accidents?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );

    commit("setAccidents", accidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
